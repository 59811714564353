import MiniSearch from 'minisearch';
import {
  Camera,
  DollarSign,
  Frown,
  FileSearch,
  LinkIcon,
  MailCheckIcon,
  MapPinIcon,
  PhoneIcon,
  Search,
  ScanText,
  Radar,
  Receipt,
  Wand2,
} from 'lucide-react';
import { ProductCard } from './ProductCard';
import { useEffect, useState } from 'react';

export function ProductList(props: { myIp: string }) {
  const products = [
    {
      id: 1,
      title: 'IP Geolocation Data',
      description:
        'Get geolocation data for an IP address including city, region, country, zip code, latitude, longitude and more.',
      icon: MapPinIcon,
      url: `/products/ip/${props.myIp}`,
    },
    {
      id: 2,
      title: 'Email Verification',
      description:
        'Verify email addresses in real-time to improve email deliverability and increase email marketing ROI.',
      icon: MailCheckIcon,
      url: '/products/email/demo@demo.com',
    },
    {
      id: 3,
      title: 'Phone Number Validation',
      description:
        'Validate phone numbers in real-time to improve phone deliverability and increase phone marketing ROI.',
      icon: PhoneIcon,
      url: '/products/phone/%2B14164810022',
    },
    {
      id: 4,
      title: 'Currency Exchange Rates',
      description:
        'Fast, Accurate, Reliable Currency Conversion & Exchange Rate API for your business for all the currencies in the world.',
      icon: DollarSign,
      url: '/products/currency/usd-to-eur-rate',
    },
    {
      id: 5,
      title: 'URL Shortening',
      description:
        'Just paste your long URL from instagram, facebook, twitter, or any other website and get a short URL in seconds for free.',
      icon: LinkIcon,
      url: '/products/short-url',
    },
    // {
    //   id: 6,
    //   title: 'URL to Screenshot',
    //   description:
    //     'Capture screenshots of any website as a high resolution image. You can also capture full page screenshots.',
    //   icon: Camera,
    //   url: '/products/screenshot/website?url=https://manyapis.com',
    // },
    {
      id: 7,
      title: 'Domain Whois Lookup',
      description:
        'Get domain name registration information including domain owner, domain registrar, expiration date, creation date, and more.',
      icon: FileSearch,
      url: '/products/domain-whois/fb.com',
    },
    {
      id: 8,
      title: 'Image to Text',
      description:
        'Convert image to text, online for free. Online text document converter to extract text from all kinds of images such as PNG, JPG.',
      icon: ScanText,
      url: '/products/image-to-text?url=https://manyapis.com/images/ocr-1.jpg',
    },
    {
      id: 9,
      title: 'NSFW Image Detection',
      description:
        'NSFW image detection automatically identifies explicit content, flagging images that contain nudity or adult content.',
      icon: Radar,
      url: '/products/nsfw-image-detection',
    },
    {
      id: 10,
      title: 'Invoice',
      description:
        'Invoice API to generate PDF invoices, receipts, quotes, estimates, and more. Create and send professional invoices to your customers.',
      icon: Receipt,
      url: '/products/invoice-generator',
    },
    {
      id: 11,
      title: 'Remove Background From Image',
      description:
        "Removed image backgrounds in just a few seconds. Don't spend hours manually picking pixels.",
      icon: Wand2,
      url: '/products/remove-image-background',
    },
  ];

  const miniSearch: MiniSearch<(typeof products)[0]> = new MiniSearch({
    fields: ['title'], // fields to index for full-text search
    storeFields: ['title', 'description', 'icon', 'url'], // fields to return with search results
    searchOptions: {
      boost: {
        // boost exact matches to the top
        title: 2,
      },
      prefix: true, // prefix match (autocomplete)
      combineWith: 'OR', // use AND operator for terms, not OR
    },
  });

  miniSearch.addAll(products);

  const [searchText, setSearchText] = useState<string>();
  const [searchResults, setSearchResults] = useState<(typeof products)[0][]>(
    products.slice(0, 12),
  );

  useEffect(() => {
    const results = miniSearch
      .search((searchText || '').trim())
      .map((result) => ({
        id: result.id,
        title: result.title,
        description: result.description,
        icon: result.icon,
        url: result.url,
      }));

    if (!(searchText || '').trim()) {
      setSearchResults(products.slice(0, 12));
    } else {
      setSearchResults(results);
    }
  }, [searchText]);

  return (
    <>
      <div className="container mx-auto">
        <div className="group mb-3 flex items-center gap-x-2 rounded-xl border p-4 focus-within:border-black/30">
          <Search
            className="flex h-[18px] text-gray-400 group-focus-within:text-gray-700"
            strokeWidth={2.2}
          />
          <input
            type="text"
            placeholder="Search for APIs.."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="flex-1 text-lg text-black outline-none"
          />
        </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 md:grid-cols-3">
          {searchResults.map((product) => (
            <ProductCard
              key={product.id}
              url={product.url}
              icon={product.icon}
              title={product.title}
              description={product.description}
            />
          ))}
          {searchResults.length === 0 && (
            <ProductCard
              url="/contact-us"
              icon={Frown}
              title="Oops! No results found."
              description="Please contact us to add your API to our marketplace."
            />
          )}
        </div>
      </div>
    </>
  );
}
